<template>
  <div class="container-fluid employees" @scroll="onScroll">
    <div class="row">
      <div class="col-12">
        <AppContentLayout tools>
          <template #title>
            <span>{{ t('navigation.employees') }}</span>
          </template>
          <template #tools-filters>
            <RoleDropdown
              class="employees-roles-dropdown"
              :roles="employeeRoles"
              :selectedRole="selectedEmployeeRole"
              @update:selectedRole="onSelectedEmployeeRoleChange"
              @clear="onSelectedEmployeeRoleClear"
            />
          </template>
          <template #tools-actions>
            <Button
              class="p-button-sm p-button-primary p-button-rounded"
              :label="t('employees.add')"
              @click="$router.push({ name: 'EmployeeSave' })"
            />
          </template>
          <Loader :is-loading="isLoading" :margin-left="navigationWidth" />
          <EmptyState
            v-if="isEmptyEmployeeList && !isLoading"
            :title="t('employees.emptyState.title')"
            :description="t('employees.emptyState.description')"
          />
          <div v-if="employees.length" class="card-grid">
            <div v-for="employee in employees" :key="employee.email">
              <UserCard
                :user="employee"
                @click="onEmployeeClickHandle(employee.userId)"
                :displayOptions="['details', 'chip']"
              />
            </div>
          </div>
        </AppContentLayout>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { default as common } from '@/config/common.json'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import RoleDropdown from '@bd/admin/components/Users/RoleDropdown/RoleDropdown.vue'
import { useAppStore } from '@bd/admin/store'
import { EmployeeParams, RoleDropdownItem } from '@bd/admin/types'
import { EmployeeRole, UserRole } from '@bd/api'
import { Loader, useInfiniteScroll, useRootData } from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import UserCard from '@bd/components/User/UserCard/UserCard.vue'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Calendar',
  components: { AppContentLayout, UserCard, EmptyState, Loader, RoleDropdown },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()

    const { navigationWidth } = common
    const { isLoading } = useRootData()
    const employeesState = store.state.employees

    const employees = computed(
      () => store.state.employees?.employees.content || [],
    )

    const employeeRoles: RoleDropdownItem[] = Object.values(EmployeeRole).map(
      (role) => ({
        label: t(`enums.userRole.${role}`),
        value: UserRole[role],
      }),
    )

    const selectedEmployeeRole = computed(() =>
      employeeRoles.find(
        (r) => r.value === store.state.employees?.filters.role,
      ),
    )

    const isEmptyEmployeeList = computed(() => {
      if (!employeesState) {
        return true
      }
      return employeesState.employees.content.length <= 0
    })

    const pageNumber = computed(() => employeesState?.filters.pageIndex || 0)

    const fetchEmployees = async (filters: EmployeeParams) => {
      await store.dispatch('employees/setFilterAndRefresh', filters)
    }

    const loadNextPage = () => {
      const pageIndex = pageNumber.value + 1
      fetchEmployees({ pageIndex })
    }

    const onEmployeeClickHandle = (id: number) => {
      router.push({ name: 'EmployeeDetails', params: { id } })
    }

    const onSelectedEmployeeRoleChange = (role: UserRole) => {
      store.dispatch('employees/setFilterAndRefresh', { role })
    }

    const onSelectedEmployeeRoleClear = () => {
      store.dispatch('employees/setFilterAndRefresh', { role: undefined })
    }

    const areAllEmployeesLoaded = computed(() => {
      if (!employeesState?.employees) {
        return false
      }
      return (
        employeesState.employees.content.length >=
        employeesState.employees.totalCount
      )
    })

    const onScroll = useInfiniteScroll(() => {
      if (!areAllEmployeesLoaded.value) {
        loadNextPage()
      }
    })

    fetchEmployees({ pageIndex: 0 })

    return {
      t,
      isLoading,
      navigationWidth,
      employees,
      employeeRoles,
      selectedEmployeeRole,
      isEmptyEmployeeList,
      onEmployeeClickHandle,
      onScroll,
      onSelectedEmployeeRoleChange,
      onSelectedEmployeeRoleClear,
    }
  },
})
</script>

<style lang="scss" scoped>
.employees {
  height: 100vh;
  overflow-y: auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 14px;
  row-gap: 40px;
}
:deep(.employees-roles-dropdown .dropdown-content) {
  min-width: 160px;
}
</style>
